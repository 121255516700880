import React from "react";
import './Footer.css';
import { storageConstants } from "../../../common/utils";

const Footer = () => {
  return (
    <footer className="footer">
      {storageConstants.PAGES.TITLE.LINKGRAM} © {new Date().getFullYear()} . All rights reserved.
    </footer>
  );
};

export default Footer;
