import {
  CameraOutlined,
  CheckCircleOutlined,
  DashboardOutlined,
  EyeInvisibleOutlined,
  HistoryOutlined,
  NotificationOutlined,
  NumberOutlined,
  PlayCircleOutlined,
  SettingOutlined,
  SolutionOutlined,
  StopOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { storageConstants } from "../../../common/utils";
const { Sider } = Layout;

function getItem(show, label, key, icon, children, type) {
  if (show) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  } else return;
}

const SideMenu = (props) => {
  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    const selectedMenuItem = event.domEvent.currentTarget; // Get the selected menu item DOM element
    const selectedMenuLabel = selectedMenuItem.textContent; // Get the label (text) of the selected menu item
    props.updateAuthUser({
      path: event.key,
      currentMenuTitle: selectedMenuLabel,
    });
    navigate(event.key);
  };

  return (
    <Sider trigger={null} collapsible collapsed={props.collapsed}>
      <div className="logo">
        <h3
          style={{
            color: "white",
            textAlign: "center",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              gap: "5px",
            }}
          >
            <img src={"logo.png"} height={25}></img>{" "}
            {props.collapsed ? "" : storageConstants.PAGES.TITLE.LINKGRAM}
          </div>
        </h3>
      </div>
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[
          props.user.path || storageConstants.PAGES.ROUTE.DASHBOARD,
        ]}
        onClick={handleMenuClick}
        items={[
          getItem(
            true,
            storageConstants.PAGES.MENU.DASHBOARD,
            storageConstants.PAGES.ROUTE.DASHBOARD,
            <DashboardOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.USER_MANAGEMENT,
            storageConstants.PAGES.ROUTE.USER_MANAGEMENT,
            <UsergroupAddOutlined />,
            [
              getItem(
                true,
                storageConstants.PAGES.MENU.USER_MANAGEMENT,
                storageConstants.PAGES.ROUTE.USER_MANAGEMENT,
                <SolutionOutlined />
              ),
              getItem(
                true,
                storageConstants.PAGES.MENU.REQUEST_VERIFICATION,
                storageConstants.PAGES.ROUTE.USER_MANAGEMENT +
                  storageConstants.PAGES.ROUTE.REQUEST_VERIFICATION,
                <CheckCircleOutlined />
              ),
            ]
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.POST_MANAGEMENT,
            storageConstants.PAGES.ROUTE.POST_MANAGEMENT,
            <CameraOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.REEL_MANAGEMENT,
            storageConstants.PAGES.ROUTE.REEL_MANAGEMENT,
            <PlayCircleOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.STORY_MANAGEMENT,
            storageConstants.PAGES.ROUTE.STORY_MANAGEMENT,
            <HistoryOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.CONTENT_REPORT_MANAGEMENT,
            storageConstants.PAGES.ROUTE.CONTENT_REPORT_MANAGEMENT,
            <EyeInvisibleOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.CONTENT_MODERATION_MANAGEMENT,
            storageConstants.PAGES.ROUTE.CONTENT_MODERATION_MANAGEMENT,
            <ExclamationCircleOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.BLOCKLIST,
            storageConstants.PAGES.ROUTE.BLOCKLIST,
            <StopOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.NOTIFICATIONS,
            storageConstants.PAGES.ROUTE.NOTIFICATIONS,
            <NotificationOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.HASHTAGS,
            storageConstants.PAGES.ROUTE.HASHTAGS,
            <NumberOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.PROFILE,
            storageConstants.PAGES.ROUTE.PROFILE,
            <UserOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.ACCOUNTS,
            storageConstants.PAGES.ROUTE.ACCOUNTS,
            <UsergroupAddOutlined />
          ),
          getItem(
            true,
            storageConstants.PAGES.MENU.SYSTEM_SETTINGS_AND_CONFIGURATION,
            storageConstants.PAGES.ROUTE.SYSTEM_SETTINGS_AND_CONFIGURATION,
            <SettingOutlined />
          ),
        ]}
      />
    </Sider>
  );
};

export default SideMenu;
